/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:aceeb641-fbef-40a5-b22b-8a3abe1183a7",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_7cL7oc7lo",
    "aws_user_pools_web_client_id": "2vogj3ied0pe0sjnfj64j6g1l0",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://o4v4ats7afa73ds7tafzdmn4im.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-a5oms3cjpfgapoxphpghtyvrx4",
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "User-uk5bmajo2zc7pjck4ht325nenu-staging",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ]
};


export default awsmobile;
