import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardContent, Link, Typography } from '@mui/material';
import './Comment.css';

/**
 * Card displaying a comment in the list beneath a post.
 */

function Comment({ content, username, contentAge }) {
  return (
    <Card className="postCard" variant="outlined">
      <CardContent
        sx={{
          '&:last-child': {
            paddingBottom: '16px',
          },
          '&': {
            paddingLeft: '2.5rem',
            paddingRight: '2.5rem',
          },
        }}
      >
        <Typography
          className="commentMeta"
          variant="subtitle1"
          color="text.secondary"
          gutterBottom
        >
          <Link
            component={RouterLink}
            to={`/user/${username}`}
            color="inherit"
            underline="hover"
            aria-label="comment author"
          >
            {username}
          </Link>
          {' · '}
          <span aria-label="comment age">{contentAge}</span>
        </Typography>
        <Typography
          className="commentText"
          variant="body1"
          aria-label="comment text"
        >
          {content}
        </Typography>
      </CardContent>
    </Card>
  );
}

Comment.propTypes = {
  /** The text of the comment. */
  content: PropTypes.string.isRequired,

  /** The username that submitted the comment. */
  username: PropTypes.string.isRequired,

  /** The comment age, as generated by `moment`. */
  contentAge: PropTypes.string.isRequired,
};

export default Comment;
